import * as React from "react";

import { Create, useCreate } from "react-admin";

import { CompanyForm, OperationType } from "./CompanyForm";

export const CompanyCreate = () => {
  const [ create ] = useCreate();
  return (
    <Create redirect="list">
      <CompanyForm operationType={OperationType.CREATE} submit={create}/>
    </Create>
  );
};
