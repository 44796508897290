import React, { useEffect, useState, useCallback, SyntheticEvent } from "react";

import {
  AutocompleteChangeReason
} from "@mui/base/useAutocomplete/useAutocomplete";
import SwapVerticalCircleIcon from "@mui/icons-material/SwapVerticalCircle";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { debounce, isEmpty } from "lodash-es";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { Link } from "react-router-dom";
import * as yup from "yup";

import { FormSelect } from "../../../components/form/input/select/FormSelect";
import { useForm } from "../../../components/form/use-form";
import { UserRoles } from "../../../core/providers/auth/roles";
import { FullCustomer, RepossessionReason, Stove, SwapDeviceReason } from "../../../utils/commons";
import { useCheckAccess } from "../../../utils/use-check-access";
import { FullStove } from "../StoveShow";

type SwapDeviceProps = {
  customer?: FullCustomer
};

type SwapDeviceFormState = {
  reason: RepossessionReason | "",
  newSerialNumberWithPrefix: string | null,
  nativeId: number | null
};

const initialFormState: SwapDeviceFormState = {
  reason: "",
  newSerialNumberWithPrefix: null,
  nativeId: null
};
const validationSchema = yup.object({
  reason: yup.string().required()
});

const swapReasons = Object.entries(SwapDeviceReason)
  .map(([key, value]) => {
    return { value: key, label: value };
  })
  .sort((a, b) => a.label.localeCompare(b.label));

export const SwapDeviceButton: React.FC<SwapDeviceProps> = ({
  customer
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const form = useForm<SwapDeviceFormState>(initialFormState, validationSchema);
  const { hasAccess } = useCheckAccess([
    UserRoles.ROLE_SUPAMOTO_ADMIN
  ]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [stoves, setStoves] = useState<readonly FullStove[]>([]);
  const [uniqueNativeIds, setUniqueNativeIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [deviceWithPrefix, setDeviceWithPrefix] = useState<string | null>(null);
  const [availableDevices, setAvailableDevices] = useState<string[]>([]);
  const [activeStove, setActiveStove] = useState<Stove | undefined>();
  const [selectedStove, setSelectedStove] = useState<FullStove | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchStoves = useCallback(debounce((value: string) => {
    setIsLoading(true);

    dataProvider.getList("stoves", {
      pagination: {
        page: 1,
        perPage: 10
      },
      sort: {
        field: "id",
        order: "DESC"
      },
      filter: {
        queryText: value
      }
    }).then((response) => {
      setStoves(response.data);
      setUniqueNativeIds(Array.from(new Set(response.data.map((stove) => stove.nativeId))));
      return response;
    }).catch(() => {
      notify("Error: failed to retrieve stoves", { type: "error" });
    }).finally(() => {
      setIsLoading(false);
    });
  }, 500), [dataProvider, notify]);

  useEffect(() => {
    if (customer && customer.stoves) {
      setActiveStove(customer.stoves.find((stove: Stove) => stove.status === "ACTIVE"));
    }
  }, [customer]);

  useEffect(() => {
    if (isEmpty(inputValue) || inputValue.length < 3) return;
    fetchStoves(inputValue);
    setDeviceWithPrefix(null);
  }, [inputValue, fetchStoves]);

  const handleCancel = () => {
    setIsFormOpen(false);
    setSelectedStove(undefined);
    setAvailableDevices([]);
  };
  const handleConfirm = () => {
    setIsFormOpen(false);
    dataProvider.updateManyByUrlWithBody(`customers/${customer?.id}/swap`, {
      newSerialNumberWithPrefix: deviceWithPrefix,
      nativeId: selectedStove?.nativeId,
      reason: form.value.reason
    }).then((response: any) => {
      refresh();
      notify("Device has been successfully swapped");
      handleCancel();
      return response;
    }).catch(() => {
      notify("Error: failed to swap device", { type: "error" });
      handleCancel();
    });
  };

  const onChange = (event: SyntheticEvent, value: number, reason: AutocompleteChangeReason) => {
    if (reason === "selectOption" && value) {
      const stove = stoves.find((stove) => stove.nativeId === value
          && stove.status === "ACTIVE")
          || stoves.find((stove) => stove.nativeId === value);

      setSelectedStove(stove);
      setInputValue(value.toString());
      if (customer) {
        getAvailableDevices(customer?.id, value);
      }
    }
  };

  const getAvailableDevices = (customerId: number, nativeId: number) => {
    return dataProvider.getManyByUrl(`customers/${customerId}/crm-available-devices/${nativeId}`)
      .then((response: {data: string[]}) => {
        if (response.data.length === 1) {
          setDeviceWithPrefix(response.data[0]);
        }
        setAvailableDevices(response.data);
        return response.data;
      })
      .catch(() => {
        notify("Error: failed to get available device on payGops", { type: "error" });
        return null;
      });
  };

  return (
    <>
      <Tooltip title="Swap Device">
        <span>
          <Button
            color="primary"
            variant={"text"}
            disabled={!hasAccess || !customer || customer.customerStatus === "INACTIVE" || customer.type === "LEAD"}
            onClick={() => setIsFormOpen(true)}
            startIcon={<SwapVerticalCircleIcon/>}>
            {"Swap Device"}
          </Button>
        </span>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={isFormOpen}
        onClose={handleCancel}
      >
        <DialogTitle>Swap device</DialogTitle>
        <DialogContent>
          <DialogContent sx={{ mx: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1" align="justify">
                  {`You are trying to swap the device for the customer with contract number 
               ${customer?.contractReference}. The customer currently owns a stove with UID: `}
                  {activeStove ? (
                    <Link to={`/stoves/devices/${activeStove.instanceId}/show`}>
                      {activeStove.nativeId}
                    </Link>
                  ) : (
                    "doesn't own any stove"
                  )}
                  {". After pressing \"Swap\", the device will be replaced with a new one."}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <FormSelect
                  name="reason"
                  label="Swapping Reason *"
                  form={form}
                  menuItems={swapReasons}/>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  loading={isLoading}
                  inputValue={inputValue}
                  options={uniqueNativeIds}
                  getOptionLabel={(nativeId: number) => `${nativeId}`}
                  onInputChange={(event, newInputValue) => {
                    if (!isNaN(Number(newInputValue))) {
                      setInputValue(newInputValue);
                    }
                  }}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose new device *"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              {selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE" &&
                  (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        You cannot assign
                        <Link to={`/stoves/devices/${selectedStove?.instanceId}/show`}>
                          {" " + selectedStove.nativeId}
                        </Link>. It is already assigned to a customer.
                      </Alert>
                    </Grid>
                  )
              }
              {selectedStove && (!selectedStove.customer || selectedStove.status !== "ACTIVE") &&
                  availableDevices.length === 0 &&
                  (
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        No devices are available on PaygOps for this stove. Please check the device status on PaygOps.
                      </Alert>
                    </Grid>
                  )
              }
              { selectedStove && (!selectedStove.customer || selectedStove.status !== "ACTIVE") &&
                availableDevices.length > 1 && (
                <Grid item xs={12}>
                  <Typography variant="body1">
                      Multiple devices are available on PaygOps. Please select one manually.
                  </Typography>
                  <RadioGroup
                    value={deviceWithPrefix}
                    onChange={(event) => setDeviceWithPrefix(event.target.value)}
                  >
                    {availableDevices.map((device, index) => (
                      <FormControlLabel
                        key={index}
                        value={device}
                        control={<Radio />}
                        label={device}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              autoFocus
              disabled={!form.isValid ||
                  selectedStove && selectedStove.customer && selectedStove.status === "ACTIVE"
            || !deviceWithPrefix}
              onClick={handleConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};