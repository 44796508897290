import React, { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { isNil } from "lodash-es";

import { ErrorData } from "../../utils/commons";
import { useStringList } from "./use-string-list";

type AssignStovesDialogProps = {
  isOpen: boolean,
  errorData?: ErrorData,
  handleConfirmClose: () => void,
  handleConfirmSubmit: (deviceIds: string[]) => void
};

export const AssignStovesDialog: React.FC<AssignStovesDialogProps> = ({
  isOpen,
  errorData,
  handleConfirmClose,
  handleConfirmSubmit
}) => {
  const { textToArray } = useStringList();
  const [deviceIds, setDeviceIds] = useState<string>("");

  const handleSubmit = () => {
    const parsedDeviceIds = textToArray(deviceIds);
    handleConfirmSubmit(parsedDeviceIds);
    setDeviceIds("");
  };

  const handleClose = () => {
    handleConfirmClose();
    setDeviceIds("");
  };

  return (
    <>
      {!errorData && (
        <Dialog
          maxWidth={"md"}
          open={isOpen}
          onClose={handleClose}>
          <DialogTitle>Assign new stoves to the company</DialogTitle>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box sx={{ flexGrow: 1 }} />
              <TextField
                label="Add new Device IDs"
                multiline
                fullWidth
                value={deviceIds}
                onChange={(e) => setDeviceIds(e.target.value)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary">Confirm</Button>
          </DialogActions>
        </Dialog>
      )}
      {errorData && (
        <Dialog open={!isNil(errorData)} onClose={handleClose}>
          <DialogTitle>Collision encountered</DialogTitle>
          <DialogContent>
            <Box>
              <Typography variant="body2">
                    Please unassign company stoves from companies.
              </Typography>
              <Typography variant="body2">Here is a list of collisions that were encountered:</Typography>
            </Box>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Message</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errorData?.errors?.map((error, index) => (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell>{error.message}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};