import * as React from "react";
import { useState } from "react";

import {
  maxLength,
  minLength,
  required, SaveButton, SelectInput,
  SimpleForm,
  TextInput, Toolbar,
  useNotify, useRecordContext,
  useRedirect
} from "react-admin";

import { Company, COMPANY_CHOICES } from "../../components/company/types";
import { Resources } from "../../resources";

export enum OperationType {
  CREATE,
  UPDATE
}

type Props = {
  operationType: OperationType,
  submit: (resource: Resources, data: any, options: any) => void
};

export const CompanyForm: React.FC<Props> = ({
  operationType,
  submit
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<Company>();
  const isCreate = operationType === OperationType.CREATE;
  const [isLoading, setIsLoading] = useState(false);

  const idParams = record ? { id: record.id } : {};
  const submitForm = (data: any) => {
    setIsLoading(true);
    submit(Resources.Companies, { ...idParams, data }, {
      onError: () => {
        setIsLoading(false);
        notify("The internal server error occurred", { type: "error" });
      },
      onSuccess: () => {
        setIsLoading(false);
        redirect("list", Resources.Companies);
        notify("The company has been submitted");
      }
    });
  };

  return (<>
    <SimpleForm
      sx={{ maxWidth: 600 }}
      toolbar={<Toolbar><SaveButton disabled={isLoading}/></Toolbar>}
      onSubmit={(data) => {
        submitForm(data);
      }}>
      {!isCreate && <TextInput source="id" fullWidth disabled/>}
      <TextInput source="name" fullWidth validate={[required(), minLength(2), maxLength(50)]}/>
      <SelectInput source="type" fullWidth validate={[required()]} choices={COMPANY_CHOICES}/>
    </SimpleForm>
  </>
  );
};