import * as React from "react";
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { isNil } from "lodash-es";
import {
  AutocompleteArrayInput, AutocompleteInput, GetListParams,
  maxLength,
  maxValue,
  minLength,
  minValue,
  NumberInput,
  ReferenceArrayInput,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar, useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect
} from "react-admin";
import { useFormContext } from "react-hook-form";

import { CollectionPartner, CollectionPartnerStatus, CollectionPartnerType } from "../../components/collections/types";
import { Location } from "../../components/location/types";
import { User, UserStatus } from "../../components/user/types";
import { UserRoles } from "../../core/providers/auth/roles";
import { MetaParams } from "../../core/providers/data/rest-data-provider";
import { Resources } from "../../resources";
import { Country, ErrorData } from "../../utils/commons";
import { usernameRenderer } from "../../utils/field-renderers";

const nameValidation = [required(), minLength(3), maxLength(250)];
const typeValidation = [required()];
const countryValidation = [required()];
const addressValidation = [required(), maxLength(250)];
const phoneNumberValidation = [required(), minLength(10), maxLength(100)];
const latitudeValidation = [required(), minValue(-90), maxValue(90)];
const longitudeValidation = [required(), minValue(-180), maxValue(180)];
const statusValidation = [required()];

const typeChoices = Object.entries(CollectionPartnerType).map(([key, value]) => ({ id: key, name: value }));
const countryChoices = Object.entries(Country).map(([key, value]) => ({ id: key, name: value }));
const statusChoices = Object.entries(CollectionPartnerStatus).map(([key, value]) => ({ id: key, name: value }));

export enum OperationType {
  CREATE,
  UPDATE
}

type Props = {
  operationType: OperationType,
  submit: (resource: Resources, data: any, options: any) => void
};

type PropsCollectionPartnersFormFields = {
  collectionPartner: CollectionPartner,
  isCreate: boolean
};

export const CollectionPartnersFormFields: React.FC<PropsCollectionPartnersFormFields> = ({
  isCreate,
  collectionPartner
}) => {
  const dataProvider = useDataProvider();
  const form = useFormContext();
  const [country, setCountry] = useState<string>(collectionPartner?.country);
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    const params = { pagination: { page: 1, perPage: 1000 } } as GetListParams;
    dataProvider.getList<Location>(Resources.Locations, params)
      .then((response) => {
        setLocations(response.data);
        return response;
      });
  }, [dataProvider]);
  const usersFilterToQuery = (searchText: any) => ({ username: searchText });
  return (<>
    <TextInput
      source="name"
      validate={nameValidation}
      fullWidth/>
    <SelectInput
      source="type"
      disabled={!isCreate}
      choices={typeChoices}
      validate={typeValidation}
      fullWidth/>
    <SelectInput
      source="country"
      choices={countryChoices}
      validate={countryValidation}
      onChange={(event) => {
        setCountry(event.target.value);
        form.setValue("locationId", null);
      }}
      fullWidth/>
    <AutocompleteInput
      source="locationId"
      choices={locations
        .filter((location) => location.country === country)
        .map((location) => ({ id: location.id, name: location.name }))
      }
      fullWidth
    />
    <TextInput
      source="address"
      validate={addressValidation}
      multiline
      fullWidth/>
    <TextInput
      source="phoneNumber"
      validate={phoneNumberValidation}
      fullWidth/>
    <NumberInput
      source="latitude"
      validate={latitudeValidation}
      fullWidth/>
    <NumberInput
      source="longitude"
      validate={longitudeValidation}
      fullWidth/>
    <ReferenceArrayInput
      source="userIds"
      reference={Resources.Users}
      perPage={500}
      filter={{
        roles: [UserRoles.ROLE_SHOP_KEEPER],
        statuses: ["ACTIVE"] as Array<keyof typeof UserStatus>
      }}>
      <AutocompleteArrayInput
        label="Users"
        filterToQuery={usersFilterToQuery}
        optionText={(user: User) => usernameRenderer(user)}
        fullWidth
      />
    </ReferenceArrayInput>
    {!isCreate &&
      <SelectInput
        source="status"
        choices={statusChoices}
        validate={statusValidation}
        fullWidth/>
    }
  </>);
};
export const CollectionPartnersForm: React.FC<Props> = ({
  operationType,
  submit
}) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const record = useRecordContext<CollectionPartner>();
  const [data, setData] = useState<any>();
  const [errorData, setErrorData] = useState<ErrorData>();
  const [isLoading, setIsLoading] = useState(false);

  const idParams = record ? { id: record.id } : {};
  const isCreate = operationType === OperationType.CREATE;

  const submitForm = (data: any, force: boolean) => {
    const meta = {
      queryParams: { force: force.toString() }
    } as MetaParams;

    setIsLoading(true);
    submit(Resources.CollectionPartners, { ...idParams, data, meta }, {
      onError: (error: any) => {
        setIsLoading(false);

        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "collectionPartners.users_linked") {
          setErrorData(error.body as ErrorData);
        } else {
          notify("The internal server error occurred", { type: "error" });
        }
      },
      onSuccess: () => {
        setIsLoading(false);
        redirect("list", Resources.CollectionPartners);
        notify("The collection point form has been submitted");
      }
    });
  };

  const onClose = () => {
    setErrorData(undefined);
    setData(undefined);
  };

  return (
    <SimpleForm
      sx={{ maxWidth: 600 }}
      toolbar={<Toolbar><SaveButton disabled={isLoading}/></Toolbar>}
      onSubmit={(data) => {
        setData(data);
        submitForm(data, false);
      }}>
      <CollectionPartnersFormFields collectionPartner={record} isCreate={isCreate}/>
      <Dialog open={!isNil(errorData)} onClose={onClose}>
        <DialogTitle>Collision encountered</DialogTitle>
        <DialogContent>
          <Box>
            <Typography variant="body2">Each user can only be assigned to one collection at a time. You can either cancel the request or select &quot;Overwrite&quot; to forcibly remove the listed users from their current collections and transfer them to this one.</Typography>
            <Typography variant="body2">Here is a list of collisions that were encountered:</Typography>
          </Box>
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Message</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errorData?.errors?.map((error, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{error.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={isLoading}
            onClick={() => submitForm(data, true)}>
            Overwrite
          </Button>
        </DialogActions>
      </Dialog>
    </SimpleForm>
  );
};