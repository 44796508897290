import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

type UnassignStoveDialogProps = {
  isOpen: boolean,
  handleConfirmClose: () => void,
  handleConfirmSubmit: () => void
};

export const UnassignStoveDialog: React.FC<UnassignStoveDialogProps> = ({
  isOpen,
  handleConfirmClose,
  handleConfirmSubmit }) => {
  return (
    <Dialog open={isOpen} onClose={handleConfirmClose}>
      <DialogTitle>Confirm Unassign Stoves</DialogTitle>
      <DialogContent>
          Are you sure you want to unassign the selected stoves?
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClose}>Cancel</Button>
        <Button onClick={handleConfirmSubmit} color="primary">Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};