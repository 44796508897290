import React from "react";

import DownloadIcon from "@mui/icons-material/Download";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { SECONDARY_COLOR } from "../../../core/theme/theme";
import { ErrorData } from "../../../utils/commons";
import { Dropzone } from "../../dropzone/Dropzone";
import { UploadFeedback } from "./UploadFeedback";

type ImportDataReprocessProps = {
  dialogTitle: string|React.ReactNode,
  dialogContentText: string|React.ReactNode,
  fileExportHeader?: string,
  data: ErrorData,
  isOpened: boolean,
  isConfirmDisabled: boolean,
  onDrop: (files: File[]) => void,
  onClose: () => void,
  onConfirm: () => void
};

const downloadCSV = <T extends Record<string, any>>(array: T[]|undefined,
  filename: string,
  titleLine: string|undefined) => {
  if (!array || !titleLine) return;

  const csvContent = [titleLine].concat(array.map((item) =>
    Object.values(item).join(",")
  )).join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
};

export const ImportDataReprocessPopup: React.FC<ImportDataReprocessProps> = ({
  dialogTitle,
  dialogContentText,
  fileExportHeader,
  data,
  isOpened,
  isConfirmDisabled,
  onDrop,
  onClose,
  onConfirm
}) => {

  return <Dialog open={isOpened}>
    <DialogTitle>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {dialogTitle}
      </Box>
    </DialogTitle>
    {
      data && <DialogContent>
        <Box sx={{ fontSize: 14, color: SECONDARY_COLOR }}>
          {data.message}
        </Box>
        {dialogContentText}
        <Box sx={{ mb: 2 }}>
          <Button
            sx={{
              textTransform: "none",
              fontSize: 14,
              fontWeight: 600,
              letterSpacing: "0.25px"
            }}
            variant="text"
            startIcon={<DownloadIcon/>}
            onClick={() => downloadCSV(data.unprocessedItems, "example.csv", fileExportHeader)}
          >
            Download data to reprocess
          </Button>
        </Box>

        <UploadFeedback data={data}/><br/>
        <Box sx={{ maxWidth: 580, minWidth: 500 }}>
          <Dropzone
            disabled={false}
            accept={{ "text/csv": [".csv"] }}
            onDrop={onDrop}
          />
        </Box>
      </DialogContent>
    }
    <DialogActions>
      <Button onClick={onClose}>
        Cancel
      </Button>
      {
        <Button onClick={onConfirm}
          disabled={isConfirmDisabled}
        >
          {"Confirm"}
        </Button>
      }
    </DialogActions>
  </Dialog>;
};