import React, { useEffect, useState } from "react";

import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Button,
  Checkbox,
  MenuItem, Select, InputLabel, FormControl
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { useDataProvider, useRecordContext, useNotify, useRefresh } from "react-admin";
import { Link } from "react-router-dom";

import { Company } from "../../components/company/types";
import { PRIMARY_COLOR } from "../../core/theme/theme";
import { Resources } from "../../resources";
import { ConnectionType, Device, DeviceStatus, ErrorData, StoveModel } from "../../utils/commons";
import { formatDateTime } from "../../utils/date-time-utils";
import { deviceStatusIcons, enumIconRenderer, enumRenderer } from "../../utils/field-renderers";
import { AssignStovesDialog } from "./AssignStovesDialog";
import { UnassignStoveDialog } from "./UnassignStoveDialog";

const renderCustomer = (record: Device) => {
  const activeStove = record.stoves.find((stove) => stove.status === "ACTIVE");
  if (!activeStove) return;
  const customer = activeStove.customer;
  return customer ? `${customer.firstName} ${customer.lastName}` : "";
};

export const CompanyStoves: React.FC = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<Company>();
  const [errorData, setErrorData] = useState<ErrorData>();
  const [instances, setInstances] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [deviceIdFilter, setDeviceIdFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [unassignOpen, setUnassignOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);

  useEffect(() => {
    if (record) {
      dataProvider.getList(Resources.Devices, {
        pagination: { page: page + 1, perPage: rowsPerPage },
        sort: { field: "id", order: "ASC" },
        filter: { companyIds: record.id,
          deviceIds: deviceIdFilter,
          stoveStatuses: statusFilter
        }
      })
        .then(({ data, total }) => {
          setInstances(data);
          setTotal(total || 0);
          return data;
        })
        .catch((error) => {
          notify("Failed to load instances:", error);
        });
    }
  }, [record, page, rowsPerPage, deviceIdFilter, statusFilter, notify, dataProvider]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDeviceIdFilter(value.split(",").map((id) => id.trim()));
    setPage(0);
  };

  const handleStatusChange = (event: SelectChangeEvent<string[]>) => {
    setStatusFilter(event.target.value as string[]);
    setPage(0);
  };

  const handleSelect = (id: string) => {
    setSelected((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(id)) {
        newSelected.delete(id);
      } else {
        newSelected.add(id);
      }
      return newSelected;
    });
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = new Set(instances.map((instance) => instance.deviceId));
      setSelected(newSelected);
    } else {
      setSelected(new Set());
    }
  };

  const handleUnassignOpen = () => setUnassignOpen(true);
  const handleUnassignClose = () => setUnassignOpen(false);

  const handleUnassignConfirm = () => {
    const selectedDeviceIds = Array.from(selected);
    dataProvider.updateManyByUrlWithBody(`${Resources.Companies}/${record.id}/unassign`,
      { deviceIds: selectedDeviceIds })
      .then((response: any) => {
        setSelected(new Set());
        setUnassignOpen(false);
        refresh();
        return response;
      })
      .catch((error: any) => {
        notify("Failed to unassign stoves:", error);
        setSelected(new Set());
        setUnassignOpen(false);
      });
  };

  const handleAssignOpen = () => setAssignOpen(true);
  const handleAssignClose = () => {
    setAssignOpen(false);
    setErrorData(undefined);
  };

  const handleAssignConfirm = (deviceIds: string[]) => {
    dataProvider.updateManyByUrlWithBody(`${Resources.Companies}/${record.id}/assign`,
      { deviceIds: deviceIds })
      .then((response: any) => {
        setAssignOpen(false);
        refresh();
        return response;
      })
      .catch((error: any) => {
        const errorCode = error.body?.errorCode;
        if (errorCode && errorCode === "company.stoves") {
          setErrorData(error.body as ErrorData);
        } else {
          notify("The internal server error occurred", { type: "error" });
          setAssignOpen(false);
          refresh();
        }
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Filter by Device ID"
            value={deviceIdFilter.join(", ")}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          />
          <Box sx={{ ml: 1 }} />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 200 }}>
            <InputLabel>Stove Statuses</InputLabel>
            <Select
              label="Stove Statuses"
              value={statusFilter}
              onChange={handleStatusChange}
              multiple={true}
            >
              {Object.keys(DeviceStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {enumRenderer(status, DeviceStatus)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <Button
            size="small"
            startIcon={<LinkIcon />}
            onClick={handleAssignOpen}
          >
              Assign Stoves
          </Button>
          <Button
            size="small"
            startIcon={<LinkOffIcon />}
            onClick={handleUnassignOpen}
            disabled={selected.size === 0}
          >
              Unassign Stoves
          </Button>
        </Box>
      </Box>
      <>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selected.size > 0 && selected.size < instances.length}
                    checked={instances.length > 0 && selected.size === instances.length}
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Device Id</TableCell>
                <TableCell>Stove UID</TableCell>
                <TableCell>Model</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Connection Type</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Time registered</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instances.map((instance) => (
                <TableRow key={instance.deviceId}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selected.has(instance.deviceId)}
                      onChange={() => handleSelect(instance.deviceId)}
                    />
                  </TableCell>
                  <TableCell>
                    <Link to={`/stoves/devices/${instance.id}/show`}
                      style={{ color: PRIMARY_COLOR, textDecoration: "none" }}
                    > {instance.deviceId}</Link>
                  </TableCell>
                  <TableCell>{instance.nativeId}</TableCell>
                  <TableCell>{enumRenderer(instance.model, StoveModel)}</TableCell>
                  <TableCell>{enumIconRenderer(instance.status, DeviceStatus, deviceStatusIcons)}</TableCell>
                  <TableCell>{enumRenderer(instance.connectionType, ConnectionType)}</TableCell>
                  <TableCell>{renderCustomer(instance)}</TableCell>
                  <TableCell>{formatDateTime(instance.timeCreated)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </>
      <UnassignStoveDialog isOpen={unassignOpen}
        handleConfirmClose={handleUnassignClose}
        handleConfirmSubmit={handleUnassignConfirm}
      />
      <AssignStovesDialog isOpen={assignOpen}
        errorData={errorData}
        handleConfirmClose={handleAssignClose}
        handleConfirmSubmit={handleAssignConfirm}
      />
    </>
  );
};