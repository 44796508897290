import * as React from "react";

import {
  Edit,
  Tab, TabbedShowLayout,
  useUpdate
} from "react-admin";

import { CompanyForm, OperationType } from "./CompanyForm";
import { CompanyStoves } from "./CompanyStoves";

export const CompanyEdit: React.FC = () => {
  const [ update ] = useUpdate();

  const CompanyShowLayout = () => {
    return (
      <TabbedShowLayout>
        <Tab label="Company">
          <CompanyForm operationType={OperationType.UPDATE} submit={update}/>
        </Tab>
        <Tab label="Company Stoves">
          <CompanyStoves/>
        </Tab>
      </TabbedShowLayout>
    );
  };

  return (
    <Edit queryOptions={{ cacheTime: 0, staleTime: 0 }}>
      <CompanyShowLayout/>
    </Edit>
  );
};
