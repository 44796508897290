import React from "react";

import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";

import { UploadFileResponse } from "../../../core/providers/data/rest-data-provider";
import { ErrorData } from "../../../utils/commons";
import { UploadFeedback } from "./UploadFeedback";

type Props = {
  response: UploadFileResponse
};

export const ImportDataResponseContent: React.FC<Props> = ({ response }) => {
  const data = response.data as ErrorData;

  return (
    <>
      <Box>
        {
          response.ok ?
            <Alert severity="success">The file has been successfully uploaded and processed. Now you can close the dialog window.</Alert> :
            <>
              {
                response.status >= 500 ?
                  <Alert severity="error">The internal server error occurred. Please re-try uploading file, or if that didn&apos;t help reach tech team.</Alert> :
                  <>
                    <Alert severity="warning">
                      <Box>{data.message}</Box>
                      <Box>Please, fix the errors and re-upload the file.</Box>
                    </Alert>
                    <UploadFeedback data={data} />
                  </>
              }
            </>
        }
      </Box>
    </>
  );
};
