import { useState } from "react";

export const useStringList = () => {
  const [isEndsWithComma, setEndsWithComma] = useState(false);

  const arrayToText = (stringArray: string[]) => {
    const joinedStrings = stringArray?.join?.(",") || "";
    return `${joinedStrings}${isEndsWithComma ? "," : ""}`;
  };

  const textToArray = (input: string) => {
    setEndsWithComma(input.endsWith(","));
    return input.split(",").map((value) => value.trim()).filter((value) => value.length > 0);
  };

  return {
    arrayToText,
    textToArray
  };
};