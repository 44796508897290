import React from "react";

import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  TextField
} from "react-admin";

import { Company, CompanyType } from "../../components/company/types";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import { enumRenderer } from "../../utils/field-renderers";

export const CompanyList: React.FC = () => {
  return (
    <List
      pagination={<DefaultPagination/>}
      perPage={25}
      exporter={false}
      hasCreate={true}>
      <Datagrid bulkActionButtons={false} size="medium">
        <TextField source="id"/>
        <TextField source="name"/>
        <FunctionField label="Type"
          render={(record: Company) => enumRenderer(record.type, CompanyType)}/>
        <DateField source="timeCreated" showTime/>
        <TextField source="numberOfStoves"/>
        <EditButton/>
      </Datagrid>
    </List>
  );
};
